import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { environment } from '@env';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMaskModule } from 'ngx-mask';

// Core
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Views/Containers
import { ErrorPageComponent } from '@container/error-page/error-page.component';
import { HomeComponent } from '@container/home/home.component';
import { NewPaymentMethodDialogComponent } from '@container/new-payment-method-dialog/new-payment-method-dialog.component';
import { PaymentDetailsViewComponent } from '@container/payment-details-view/payment-details-view.component';
import { PaymentComponent } from '@container/payment/payment.component';
import { TopicComponent } from '@container/topic/topic.component';
import { TroubleshootingGuideComponent } from '@container/troubleshooting-guide/troubleshooting-guide.component';

// Components
import { ActionAgreementComponent } from '@component/action-agreement/action-agreement.component';
import { AddBankAccountComponent } from '@component/add-bank-account/add-bank-account.component';
import { AddCreditCardComponent } from '@component/add-credit-card/add-credit-card.component';
import { BankCheckImageComponent } from '@component/bank-check-image/bank-check-image.component';
import { BankInfoComponent } from '@component/bank-info/bank-info.component';
import { BannerComponent } from '@component/banner/banner.component';
import { CreditCardImageComponent } from '@component/credit-card-image/credit-card-image.component';
import { CreditCardInfoComponent } from '@component/credit-card-info/credit-card-info.component';
import { ErrorComponent } from '@component/error/error.component';
import { HeaderComponent } from '@component/header/header.component';
import { NewPaymentMethodComponent } from '@component/new-payment-method/new-payment-method.component';
import { PaymentContactInfoComponent } from '@component/payment-contact-info/payment-contact-info.component';
import { PaymentMethodsComponent } from '@component/payment-methods/payment-methods.component';
import { RecentUpdateCardComponent } from '@component/recent-update-card/recent-update-card.component';
import { PaymentDetailsComponent } from '@container/payment-details/payment-details.component';
import { CustomSnackbarComponent } from '@component/custom-snackbar/custom-snackbar.component';

// Directives
import { CurrencyFormatDirective } from '@directive/currency-format.directive';
import { ErrorMessageDirective } from '@directive/error-message.directive';
import { FeatureFlagDirective } from '@directive/feature-flag.directive';
import { MaskCcDateAndCvvDirective } from '@directive/mask-cc-date-and-cvv.directive';
import { MaskCcInfoDirective } from '@directive/mask-cc-info.directive';
import { PositiveNumberDirective } from '@directive/positive-number.directive';

// Services
import { AnalyticsService } from '@service/analytics/analytics-service';
import { ApigeeService } from '@service/apigee/apigee.service';
import { FisService } from '@service/fis/fis.service';
import { GraphService } from '@service/graph/graph.service';
import { InputPatternService } from '@service/input-pattern/input-pattern.service';
import { LocationService } from '@service/locations/location.service';
import { PaymentEventsService } from '@service/payment-events/payment-events.service';
import { TroubleshootingService } from '@service/troubleshooting/troubleshooting.service';

// Interceptors/Guards
import { ErrorHandlerInterceptor } from '@interceptor/error-handler.interceptor';

// Pipes
import { DatePipe } from '@angular/common';

// Validators
import {
  troubleshootingTopics,
  troubleshootingTopicsToken
} from '@service/troubleshooting/troubleshooting.constants';
import { ConfirmValidatorDirective } from '@validator/confirm-validator';
import { MatModalComponent } from './shared/components/mat-modal/mat-modal.component';
import { TimeAgoPipe } from './shared/pipes/time-ago.pipe';
import { InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MaskBankAccountNumberDirective } from './shared/directives/mask-bank-account-number.directive';

@NgModule({
  declarations: [
    AddBankAccountComponent,
    AddCreditCardComponent,
    AppComponent,
    BankInfoComponent,
    BannerComponent,
    ConfirmValidatorDirective,
    CreditCardInfoComponent,
    CurrencyFormatDirective,
    ErrorMessageDirective,
    ErrorPageComponent,
    FeatureFlagDirective,
    HeaderComponent,
    HomeComponent,
    NewPaymentMethodComponent,
    NewPaymentMethodDialogComponent,
    PaymentComponent,
    PaymentContactInfoComponent,
    PaymentDetailsComponent,
    PaymentDetailsViewComponent,
    PaymentMethodsComponent,
    ErrorComponent,
    PositiveNumberDirective,
    RecentUpdateCardComponent,
    TopicComponent,
    TroubleshootingGuideComponent,
    CreditCardImageComponent,
    BankCheckImageComponent,
    ActionAgreementComponent,
    MaskCcInfoDirective,
    MaskCcDateAndCvvDirective,
    MatModalComponent,
    TimeAgoPipe,
    MaskBankAccountNumberDirective,
    CustomSnackbarComponent
  ],
  imports: [
    BrowserModule, // BrowserModule should always be imported first
    BrowserAnimationsModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatOptionModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.newAzureAd.clientId,
          authority: environment.newAzureAd.authority,
          redirectUri: '/payment/new',
          postLogoutRedirectUri: '/payment/new'
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: true
        },
        system: {
          loggerOptions: {
            piiLoggingEnabled: false,
            logLevel: LogLevel.Verbose
          }
        }
      }),
      {
        interactionType: InteractionType.Popup,
        authRequest: {
          scopes: ['user.read']
        }
      },
      {
        interactionType: InteractionType.Popup,
        protectedResourceMap: new Map<string, Array<string> | null>([['/v1', ['user.read']]])
      }
    ),
    NgxMaskModule.forRoot({
      dropSpecialCharacters: false
    }),
    ReactiveFormsModule,
    AppRoutingModule // AppRoutingModule should always be imported last
  ],
  providers: [
    AnalyticsService,
    ApigeeService,
    GraphService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    },
    DatePipe,
    FisService,
    InputPatternService,
    LocationService,
    PaymentEventsService,
    TroubleshootingService,
    { provide: troubleshootingTopicsToken, useValue: troubleshootingTopics }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
