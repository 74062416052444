import { Component } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-mat-modal',
  templateUrl: './mat-modal.component.html',
  styleUrls: ['./mat-modal.component.scss']
})
export class MatModalComponent {
  public description: string;

  constructor(
    private dialogRef: MatDialogRef<MatModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public save() {
    this.dialogRef.close({ makePayment: true });
  }

  public close() {
    this.dialogRef.close({ makePayment: false });
  }
}
