import { Component, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute } from '@angular/router';
import { map, take } from 'rxjs/operators';

import { PaymentDetails } from '@interface/payment-details';
import { PaymentEvent, PaymentEventExtra } from '@interface/payment-event';
import { GraphService } from '@service/graph/graph.service';
import { PaymentEventsService } from '@service/payment-events/payment-events.service';
import { CustomSnackbarComponent } from '@component/custom-snackbar/custom-snackbar.component';

@Component({
  selector: 'app-payment-details-view',
  templateUrl: './payment-details-view.component.html',
  styleUrls: ['./payment-details-view.component.scss']
})
export class PaymentDetailsViewComponent implements OnInit {
  public paymentDetails: PaymentDetails;
  public fisAccountId: string;
  public paymentReferenceId: string;
  public invoiceNumber: string;
  public quoteNumber: string;
  public nonInvoicedAccountNumber: string;
  protected initComplete = false;

  constructor(
    private route: ActivatedRoute,
    private paymentEventsService: PaymentEventsService,
    private matSnackBar: MatSnackBar,
    private graphService: GraphService
  ) {}

  public ngOnInit() {
    const params = this.route?.snapshot?.params;
    const queryParams = this.route?.snapshot?.queryParams;

    this.route.data
      .pipe(
        take(1),
        map((data) => data.paymentDetails)
      )
      .subscribe((paymentDetails) => {
        this.fisAccountId = params.fisAccountId || queryParams.fisAccountId;
        this.paymentReferenceId = params.paymentReferenceId;
        this.invoiceNumber = queryParams.invoiceNumber;
        this.quoteNumber = queryParams.quoteNumber;
        this.nonInvoicedAccountNumber = queryParams.nonInvoicedAccountNumber;

        if (paymentDetails) {
          this.paymentDetails = paymentDetails;

          // When payment is successful, send payment event
          if (paymentDetails.status.toLowerCase() === 'success') {
            this.graphService.getUserProfile().subscribe((user) => {
              const userName = user.userPrincipalName.split('@')[0];
              const { quoteNumber, invoiceNumber, nonInvoicedAccountNumber } = queryParams;
              const paymentEventExtra: PaymentEventExtra = {
                quoteNumber,
                invoiceNumber,
                nonInvoicedAccountNumber,
                userName
              };
              const paymentEvent = new PaymentEvent(paymentDetails, paymentEventExtra);

              this.paymentEventsService.sendEvent(paymentEvent).subscribe(
                () => {
                  this.matSnackBar.openFromComponent(CustomSnackbarComponent, {
                    data: {
                      snackbarMessage: 'Payment confirmation sent to SalesForce',
                      snackBarButtonMessage: 'dismiss',
                      dataAutomationId: 'paymentSuccessToastMessage'
                    }
                  });
                },
                ({ message }) => {
                  console.log(message);
                  this.matSnackBar.openFromComponent(CustomSnackbarComponent, {
                    data: {
                      snackbarMessage: `⚠️ An error occurred while sending payment confirmation to SalesForce.
                      Please refresh this page to try again.`,
                      snackBarButtonMessage: 'dismiss',
                      dataAutomationId: 'paymentErrorToastMessage'
                    }
                  });
                }
              );
            });
          }
        }

        this.initComplete = true;
      });
  }
}
