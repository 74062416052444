import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA, MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.scss']
})
export class CustomSnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      snackbarMessage: string;
      snackBarButtonMessage: string;
      dataAutomationId: string;
    },
    private _snackRef: MatSnackBarRef<CustomSnackbarComponent>
  ) {}

  public dismissSnackbar(): void {
    this._snackRef.dismiss();
  }
}
