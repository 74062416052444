import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { FisAccountTypes } from '@enum/fis-account-types';
import { Payment, PaymentContact, PaymentResult } from '@interface/payment';
import { PaymentMethodInfo, PaymentMethodRequest } from '@interface/payment-method';
import { PopulatedErrorStateMatcher } from '@matcher/populated-error-state';
import { AnalyticsService } from '@service/analytics/analytics-service';
import { ApigeeService } from '@service/apigee/apigee.service';
import { FisService } from '@service/fis/fis.service';
import { InputPatternService } from '@service/input-pattern/input-pattern.service';
import { FormatValidators } from '@validator/format-validators';
import { NumberValidators } from '@validator/number-validators';

import { MatModalComponent } from '../../components/mat-modal/mat-modal.component';
import { ModelFormGroup, PaymentForm } from '@interface/form-type.model';
import { FormControl } from '@angular/forms';

enum PaymentTab {
  Saved,
  NewCard,
  NewBank
}

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  providers: [{ provide: ErrorStateMatcher, useClass: PopulatedErrorStateMatcher }]
})
export class PaymentComponent {
  public paymentForm: FormGroup<PaymentForm>;
  public paymentContactInfoForm: ModelFormGroup<PaymentContact>;
  public paymentMethodBankForm: ModelFormGroup<PaymentMethodInfo>;
  public paymentMethodCreditForm: ModelFormGroup<PaymentMethodInfo>;
  public paymentMethodSelectForm: ModelFormGroup<PaymentMethodInfo>;
  public isSubmitting = false;

  public accountNumber: string;
  public nonInvoicedAccountNumber: string;
  public invoiceNumber: string;
  public quoteNumber: string;
  public cdhCustomerId: string;
  public amount: number;
  public externalId: string;
  public firstName: string;
  public lastName: string;
  public submissionError: string;
  public pageLoadTime;
  public email: string;

  public badAccountNumber = false;
  public FIS_PREFIX = '30';
  public achBannerMessage = `Temporary containers (large and small) where payment is made before service (credit limit
    scenario, prepayment, etc. ) must be paid for by Credit/Debit card, ONLY. ACH (Checking or Savings
    account) should not be accepted.`;

  public customPatterns = {};

  private _inlinePayment = false;
  public get inlinePayment(): boolean {
    return this._inlinePayment;
  }
  public set inlinePayment(value: boolean) {
    this._inlinePayment = value;
    this.setTabForm(value ? PaymentTab.NewCard : PaymentTab.Saved);
  }

  protected contactInfo: PaymentContact;
  protected paymentMethodInfo: PaymentMethodInfo;
  protected payment: Payment;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private fisService: FisService,
    private apigeeService: ApigeeService,
    private router: Router,
    private analytics: AnalyticsService,
    private inputMaskService: InputPatternService,
    public dialog: MatDialog
  ) {
    this.accountNumber = '';
    this.nonInvoicedAccountNumber = '';
    this.invoiceNumber = '';
    this.quoteNumber = '';
    this.amount = 0;
    this.firstName = '';
    this.lastName = '';
    this.email = '';

    this.setValidationPatterns();
    this.readQueryParams();
    this.createForm();
    this.pageLoadTime = new Date();

    this.contactInfo = new PaymentContact();
    this.paymentMethodInfo = new PaymentMethodInfo();
    this.payment = new Payment({
      contactInfo: this.contactInfo,
      paymentMethodInfo: this.paymentMethodInfo
    });

    // Set active tab to the first tab by default
    // Fixes DE14815
    this.setTabForm(this.inlinePayment ? 1 : 0);
  }

  public setValidationPatterns() {
    this.customPatterns['A'] = this.inputMaskService.safeASCII;
  }

  public readQueryParams() {
    this.route.queryParams.subscribe(
      ({
        accountNumber,
        invoiceNumber,
        quoteNumber,
        cdhCustomerId,
        amount,
        nonInvoicedAccountNumber,
        email
      }) => {
        this.setAccountNumber(accountNumber);

        if (invoiceNumber) {
          this.invoiceNumber = invoiceNumber;
        }

        if (quoteNumber) {
          this.quoteNumber = quoteNumber;
        }

        if (cdhCustomerId) {
          this.cdhCustomerId = cdhCustomerId;
          this.getFisPaymentMethods(this.cdhCustomerId);
          this.getFisUsername(this.cdhCustomerId);
        } else {
          this.inlinePayment = true;
        }

        if (email) {
          this.email = email;
        }

        if (amount) {
          this.amount = parseFloat(parseFloat(amount.replace(/,/g, '')).toFixed(2));
        }

        if (nonInvoicedAccountNumber) {
          this.nonInvoicedAccountNumber = nonInvoicedAccountNumber;
        }

        if (this.paymentForm) {
          this.paymentForm.patchValue({
            accountId: this.accountNumber,
            billReferenceId: this.invoiceNumber,
            paymentAmount: this.amount,
            contactInfo: {
              firstName: this.firstName,
              lastName: this.lastName
            }
          });
        }
      }
    );
  }

  private setAccountNumber(accountNumber) {
    if (accountNumber) {
      if (accountNumber.length === 10) {
        // account number appears to be 10 digit non FIS so append FIS prefix
        this.accountNumber = `${this.FIS_PREFIX}${accountNumber}`;
      } else {
        if (accountNumber.length !== 12 || accountNumber.substring(0, 2) !== this.FIS_PREFIX) {
          // account number does not match FIS 12 digit account starting with "30"
          this.badAccountNumber = true;
        }

        this.accountNumber = accountNumber;
      }
    }
  }

  private getFisPaymentMethods(cdhCustomerId: string, refresh?: boolean) {
    this.fisService
      .getFisPaymentMethods(cdhCustomerId, refresh)
      .pipe(
        catchError((err) => {
          if (err.status === 404) {
            this.inlinePayment = true;
          }

          return of();
        })
      )
      .subscribe();
  }

  private getFisUsername(cdhCustomerId: string) {
    if (this.cdhCustomerId) {
      this.fisService.getFisUsername(cdhCustomerId).subscribe((fisUsername) => {
        this.externalId = fisUsername;
      });
    }
  }

  public createForm() {
    const datePipe = new DatePipe('en-US');
    const payDate = datePipe.transform(new Date(), 'MMddyyyy');

    this.paymentMethodBankForm = this.fb.group({});
    this.paymentMethodCreditForm = this.fb.group({});
    this.paymentMethodSelectForm = this.fb.group({});
    this.paymentMethodSelectForm.addControl('paymentMethodId', new FormControl(''));
    this.paymentContactInfoForm = this.fb.group({});
    this.paymentForm = this.fb.group(
      {
        accountId: [
          this.accountNumber,
          [
            Validators.required,
            Validators.minLength(12),
            Validators.pattern(/^\d{12}$/),
            FormatValidators.prefixedWith(this.FIS_PREFIX)
          ]
        ],
        billReferenceId: [
          this.invoiceNumber || null,
          [Validators.required, NumberValidators.numericMaxLength(15)]
        ],
        hasAgreedToStore: ['false'],
        hasAgreedToUse: ['true'],
        paymentAmount: [
          this.amount || null,
          [Validators.required, Validators.min(1), Validators.max(150000)]
        ],
        paymentDate: [payDate, [Validators.required]],
        paymentMethodInfo: this.paymentMethodSelectForm,
        contactInfo: this.fb.group({
          firstName: [this.firstName, Validators.required],
          lastName: [this.lastName, Validators.required],
          email: [this.email, Validators.email]
        })
      },
      {
        validator: this.contactInfoValidator()
      }
    );

    // TODO: The pipes here are commented because they affect the tests, which should be
    // rewritten to accomodate these efficiency changes.
    this.paymentForm.valueChanges

      // .pipe(
      //   throttleTime(350, undefined, { leading: true, trailing: true }),
      //   distinctUntilChanged()
      // )
      .subscribe((values) => this.handlePaymentDetails(values));

    this.paymentContactInfoForm.valueChanges

      // .pipe(
      //   throttleTime(350, undefined, { leading: true, trailing: true }),
      //   distinctUntilChanged()
      // )
      .subscribe((values) => this.handleContactInfo(values));

    this.validateAccountNumber();
  }

  private contactInfoValidator(): ValidatorFn {
    return (): { [key: string]: any } => {
      // Only use contact info validity if we have bank info
      const usingBankInfo =
        this.paymentForm &&
        this.paymentForm.controls &&
        this.paymentForm.controls.paymentMethodInfo.value.bankInfo;

      if (usingBankInfo) {
        return this.paymentContactInfoForm && this.paymentContactInfoForm.invalid
          ? { contactInfoInvalid: true }
          : null;
      }

      return null;
    };
  }

  private handlePaymentDetails(values) {
    this.contactInfo = new PaymentContact({
      ...this.contactInfo,
      ...values.contactInfo,
      ...this.paymentContactInfoForm.value
    });

    if (typeof values.paymentMethodInfo.cardInfo !== 'undefined') {
      values.hasAgreedToStore = !!values.paymentMethodInfo.cardInfo.saveCard;
      this.paymentMethodInfo = this.cardPayment(values.paymentMethodInfo);
    } else if (typeof values.paymentMethodInfo.bankInfo !== 'undefined') {
      values.hasAgreedToStore = !!values.paymentMethodInfo.saveBank;
      this.paymentMethodInfo = this.bankPayment(values.paymentMethodInfo);
      values.contactInfo = this.contactInfo;
    } else if (typeof values.paymentMethodInfo.paymentMethodId !== 'undefined') {
      this.paymentMethodInfo = new PaymentMethodInfo(values.paymentMethodInfo);
      values.externalId = this.externalId;
    }

    values.paymentMethodInfo = this.paymentMethodInfo;
    this.payment = new Payment(values);
  }

  private handleContactInfo(values) {
    if (this.payment.paymentMethodInfo.bankInfo) {
      values.contactInfo.postalCode = (values.contactInfo.postalCode || '').trim();
    } else {
      values.contactInfo = { ...this.payment.contactInfo };
    }

    this.contactInfo = new PaymentContact(values.contactInfo);
  }

  private cardPayment(paymentMethodValue): PaymentMethodInfo {
    const cardInfo = paymentMethodValue.cardInfo;
    const cardNumber = (cardInfo.creditCardNumber || '').trim();
    const cardType = FisAccountTypes[this.apigeeService.creditCardType(cardNumber)];
    const billingInfo = cardInfo.billingInfo;

    const now = new Date();
    const values = {
      accountType: cardType,
      accountNickName: this.apigeeService.creditCardNickName(cardType, cardNumber),
      cardInfo: {
        billingInfo: {
          ...billingInfo,
          postalCode: (billingInfo.postalCode || '').trim()
        },
        creditCardCvv: (cardInfo.creditCardCvv || '').trim(),
        creditCardMonth: cardInfo.creditCardMonth.toString().padStart(2, '0'),
        creditCardName: this.apigeeService.creditCardName(
          billingInfo.firstName,
          billingInfo.middleInitial,
          billingInfo.lastName
        ),
        creditCardNumber: cardNumber,
        creditCardYear: now.getFullYear().toString().substring(0, 2) + cardInfo.creditCardYear
      }
    };

    return new PaymentMethodInfo(values);
  }

  private bankPayment(paymentMethodValue): PaymentMethodInfo {
    const accountType = paymentMethodValue.accountType;
    const bankInfo = paymentMethodValue.bankInfo;

    const accountNickName = this.apigeeService.bankNickName(accountType, bankInfo.bankName);

    const values = {
      accountType,
      accountNickName,
      bankInfo: {
        accountHolderName: (bankInfo.accountHolderName || '').trim(),
        accountNumber: (bankInfo.accountNumber || '').trim(),
        bankName: (bankInfo.bankName || '').trim(),
        routingNumber: (bankInfo.routingNumber || '').trim()
      }
    };

    return new PaymentMethodInfo(values);
  }

  public openDialog(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;

    dialogConfig.data = {
      firstName: this.payment.contactInfo.firstName,
      lastName: this.payment.contactInfo.lastName,
      payment: this.payment.paymentAmount,
      description: 'Confirm Payment Information'
    };

    const dialogRef = this.dialog.open(MatModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((data) => {
      if (data.makePayment === true) {
        this.submitPayment();
      }
    });
  }

  public submitPayment() {
    this.submissionError = null;
    this.isSubmitting = true;
    this.paymentForm.disable({ emitEvent: false });

    const shouldStore = this.payment.hasAgreedToStore;
    const isNewPayment = typeof this.payment.paymentMethodInfo.paymentMethodId === 'undefined';

    of(shouldStore && isNewPayment)
      .pipe(
        switchMap((storePaymentMethod) => {
          if (!storePaymentMethod) {
            let accountNumber = '';

            if (this.nonInvoicedAccountNumber.length === 10) {
              accountNumber = `${this.FIS_PREFIX}${this.nonInvoicedAccountNumber}`;
            } else {
              accountNumber = this.paymentForm.controls.accountId.value;
            }

            return this.processPayment(accountNumber);
          }

          return this.storePaymentMethod().pipe(
            switchMap((paymentMethodId) => {
              this.paymentMethodInfo = new PaymentMethodInfo({
                paymentMethodId
              });
              this.payment = new Payment({
                ...this.payment,
                externalId: this.externalId,
                paymentMethodInfo: this.paymentMethodInfo
              });

              return this.processPayment(this.paymentForm.controls.accountId.value);
            })
          );
        })
      )
      .subscribe(
        (res: PaymentResult) => {
          const urlTree: UrlTree = this.router.parseUrl(
            `/account/${this.payment.accountId}` +
              `/payment/${res.paymentId}` +
              `?invoiceNumber=${res.billReferenceId}` +
              `&quoteNumber=${this.quoteNumber}` +
              `&nonInvoicedAccountNumber=${this.nonInvoicedAccountNumber}`
          );

          this.router.navigateByUrl(urlTree);
        },
        (error: Error) => {
          if (this.nonInvoicedAccountNumber.length === 10) {
            this.submissionError = 'Non-Invoiced account not found; Attempting holding account...';
            this.processPayment(this.paymentForm.controls.accountId.value).subscribe(
              (res: PaymentResult) => {
                const urlTree: UrlTree = this.router.parseUrl(
                  `/account/${this.payment.accountId}` +
                    `/payment/${res.paymentId}` +
                    `?invoiceNumber=${res.billReferenceId}` +
                    `&quoteNumber=${this.quoteNumber}` +
                    `&nonInvoicedAccountNumber=${this.nonInvoicedAccountNumber}`
                );

                this.router.navigateByUrl(urlTree);
              },
              (error2: Error) => {
                this.isSubmitting = false;
                this.submissionError = error2.message;
                this.paymentForm.enable({ emitEvent: false });
              }
            );
          } else {
            this.isSubmitting = false;
            this.submissionError = error.message;
            this.paymentForm.enable({ emitEvent: false });
          }
        }
      );
  }

  private storePaymentMethod(): Observable<any> {
    const paymentMethodRequest = new PaymentMethodRequest({
      hasAgreedToStore: 'true',
      hasAgreedToUse: 'true',
      externalId: this.externalId,
      paymentMethodInfo: this.payment.paymentMethodInfo
    });

    return this.apigeeService.createPaymentMethod(paymentMethodRequest).pipe(
      catchError((error) => {
        const errorMessage =
          error?.error?.errors?.[0]?.message ||
          error?.statusText ||
          'There was an error processing your request';

        return throwError(new Error(errorMessage));
      }),
      map((res) => {
        if (res?.errors) {
          const errorMessage =
            res?.errors?.[0]?.ApplMsgTxt || 'There was an error processing your request';

          return throwError(new Error(errorMessage));
        }

        return `${res.id}`;
      })
    );
  }

  private processPayment(accountNumber: string): Observable<PaymentResult> {
    this.payment.contactInfo.firstName = (this.payment.contactInfo.firstName || '').trim();
    this.payment.contactInfo.lastName = (this.payment.contactInfo.lastName || '').trim();
    this.payment.accountId = accountNumber;

    if (this.payment.paymentMethodInfo.hasOwnProperty('bankInfo')) {
      this.payment.contactInfo.addressLine1 =
        this.paymentContactInfoForm.value.contactInfo.addressLine1;
      this.payment.contactInfo.addressLine2 =
        this.paymentContactInfoForm.value.contactInfo.addressLine2;
      this.payment.contactInfo.city = this.paymentContactInfoForm.value.contactInfo.city;
      this.payment.contactInfo.countryCode =
        this.paymentContactInfoForm.value.contactInfo.countryCode;
      this.payment.contactInfo.postalCode =
        this.paymentContactInfoForm.value.contactInfo.postalCode;
      this.payment.contactInfo.stateCode = this.paymentContactInfoForm.value.contactInfo.stateCode;
    }

    if (this.payment.paymentMethodInfo.hasOwnProperty('cardInfo')) {
      this.payment.contactInfo.addressLine1 =
        this.paymentMethodCreditForm.value.cardInfo.billingInfo.addressLine1;
      this.payment.contactInfo.addressLine2 =
        this.paymentMethodCreditForm.value.cardInfo.billingInfo.addressLine2;
      this.payment.contactInfo.city = this.paymentMethodCreditForm.value.cardInfo.billingInfo.city;
      this.payment.contactInfo.countryCode =
        this.paymentMethodCreditForm.value.cardInfo.billingInfo.countryCode;
      this.payment.contactInfo.postalCode =
        this.paymentMethodCreditForm.value.cardInfo.billingInfo.postalCode;
      this.payment.contactInfo.stateCode =
        this.paymentMethodCreditForm.value.cardInfo.billingInfo.stateCode;
    }

    return this.apigeeService.createPayment(this.payment, accountNumber).pipe(
      catchError((error) => {
        const errorMessage =
          error?.error?.errors?.[0]?.message ||
          error?.statusText ||
          'There was an error processing your request';

        throw new Error(errorMessage);
      }),
      tap((response: PaymentResult) => {
        this.trackPaymentSubmission(this.payment, response);
      }),
      map((res) => {
        if (res.status !== 'Success') {
          let errorMessage = '';

          if (
            res &&
            res['originalResponse'] &&
            res['originalResponse']['errors'] &&
            res['originalResponse']['errors'][0] &&
            res['originalResponse']['errors'][0]['ApplMsgTxt']
          ) {
            errorMessage = res['originalResponse']['errors'][0]['ApplMsgTxt'];
          } else {
            errorMessage = 'There was an error processing your request';
          }

          throw new Error(errorMessage);
        }

        return res;
      })
    );
  }

  public hasQueryData() {
    return this.firstName || this.lastName || this.accountNumber || this.invoiceNumber;
  }

  public paymentMethodCheckValidity() {
    this.paymentForm.updateValueAndValidity();
  }

  public setActiveForm(activeForm: ModelFormGroup<PaymentMethodInfo>) {
    this.paymentForm.controls.paymentMethodInfo = activeForm;
    this.paymentForm.updateValueAndValidity();
  }

  public paymentMethodChanged(event: MatTabChangeEvent) {
    const activeTab = this.inlinePayment ? event.index + 1 : event.index;

    this.setTabForm(activeTab);
  }

  private setTabForm(tab: PaymentTab) {
    if (!this.paymentForm) {
      return;
    }

    switch (tab) {
      case PaymentTab.Saved:
        this.paymentForm.controls.paymentMethodInfo = this.paymentMethodSelectForm;
        break;

      case PaymentTab.NewCard:
        this.paymentForm.controls.paymentMethodInfo = this.paymentMethodCreditForm;
        break;

      case PaymentTab.NewBank:
        this.paymentForm.controls.paymentMethodInfo = this.paymentMethodBankForm;
        break;

      default:
    }

    this.paymentForm.updateValueAndValidity();
  }

  public trackPaymentSubmission(payment, response) {
    const analytics = {
      timeOnPaymentPage: Date.now() - this.pageLoadTime,
      originalAmount: this.amount
    };
    const analyticsData = { ...payment, ...response, ...analytics };

    this.analytics.trackPaymentSubmission(analyticsData);
  }

  private validateAccountNumber() {
    if (this.badAccountNumber) {
      this.paymentForm.controls.accountId.markAsTouched();
      this.paymentForm.controls.accountId.markAsDirty();
      this.paymentForm.controls.accountId.updateValueAndValidity();
    }
  }
}
