import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { ModelFormGroup } from '@interface/form-type.model';
import { CountryCode, State } from '@interface/location';
import { PaymentContact } from '@interface/payment';
import { BillingInfo } from '@interface/payment-method';
import { PopulatedErrorStateMatcher } from '@matcher/populated-error-state';
import { InputPatternService } from '@service/input-pattern/input-pattern.service';
import { LocationService } from '@service/locations/location.service';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-payment-contact-info',
  templateUrl: './payment-contact-info.component.html',
  providers: [{ provide: ErrorStateMatcher, useClass: PopulatedErrorStateMatcher }]
})
export class PaymentContactInfoComponent implements OnInit, AfterViewInit {
  @Input() public showForm = false;
  @Input() public parentForm: ModelFormGroup<PaymentContact>;
  @Output() public checkValidity: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  @ViewChild('mySelect', { read: ElementRef }) public matSelectElementRef: ElementRef;

  public states: State[];
  public fisUsername: string;
  public accountHolderName: string;
  public firstName: string;
  public middleInitial: string;
  public lastName: string;
  public addressLine1: string;
  public addressLine2: string;
  public city: string;
  public stateCode: string;
  public postalCode: string;
  public countryCode: CountryCode = CountryCode.US;

  public form: ModelFormGroup<PaymentContact>;
  public contactInfo: BillingInfo;
  public contactInfoForm: ModelFormGroup<PaymentContact>;
  public customPatterns = {};
  public zipCodeMask = '00000-0000';
  public canadaZipCodeMask = 'F0L 0L0';
  public canadaZipCodePattern = {
    '0': { pattern: /\d/ },
    F: { pattern: /[ABCEGHJKLMNPRSTVXY]/i },
    L: { pattern: /[ABCEGHJKLMNPRSTVWXYZ]/i }
  };

  public countries = [
    { name: 'United States', code: 'US' },
    { name: 'Canada', code: 'CA' }
  ];

  private _postalCodeMask: Array<RegExp | string> = [];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private locationService: LocationService,
    private inputMaskService: InputPatternService,
    private renderer: Renderer2
  ) {
    this.setValidationPatterns();
    this.readQueryParams();
  }

  public ngAfterViewInit() {
    if (this.matSelectElementRef) {
      // Add the automation-id attribute to the native element
      this.renderer.setAttribute(
        this.matSelectElementRef.nativeElement,
        'automation-id',
        'stateCodeSelect'
      );
    }
  }

  public setValidationPatterns() {
    this.customPatterns['A'] = this.inputMaskService.safeASCII;
  }

  public createForm() {
    this.form = this.parentForm ? this.parentForm : this.fb.group({});
    this.contactInfoForm = this.fb.group({});
    this.contactInfoForm.addControl(
      'addressLine1',
      new FormControl(this.addressLine1, [Validators.required, Validators.maxLength(50)])
    );
    this.contactInfoForm.addControl(
      'addressLine2',
      new FormControl(this.addressLine2, [Validators.maxLength(50)])
    );
    this.contactInfoForm.addControl(
      'city',
      new FormControl(this.city, [Validators.required, Validators.maxLength(32)])
    );
    this.contactInfoForm.addControl(
      'stateCode',
      new FormControl(this.stateCode, [Validators.required])
    );
    this.contactInfoForm.addControl(
      'postalCode',
      new FormControl(this.postalCode, [Validators.required])
    );
    this.contactInfoForm.addControl(
      'countryCode',
      new FormControl(this.countryCode, [Validators.required])
    );

    this.form.addControl('contactInfo', this.contactInfoForm);
    this.setPostalMask(this.countryCode);
  }

  public readQueryParams() {
    this.route.queryParams.subscribe(
      ({ addressLine1, addressLine2, city, postalCode, stateCode, countryCode }) => {
        this.addressLine1 = addressLine1 || '';
        this.addressLine2 = addressLine2 || '';
        this.city = city || '';
        this.stateCode = stateCode || '';
        this.postalCode = postalCode || '';

        if (countryCode) {
          // Use appropriate 2-letter country code, or default to 'US'
          this.countryCode =
            (CountryCode[countryCode.toUpperCase()] as CountryCode) || CountryCode.US;
        } else {
          this.countryCode = CountryCode.US;
        }
      }
    );
  }

  public onChanges() {
    this.form.valueChanges.subscribe(() => {
      this.checkValidity.emit(this.form.valid);
    });

    this.contactInfoForm.controls.countryCode.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((code: CountryCode) => {
        if (code) {
          this.states = this.locationService.getStates(code);
          this.contactInfoForm.controls.stateCode.reset();
          this.contactInfoForm.controls.postalCode.reset();
          this.setPostalMask(code);
        }
      });
  }

  public ngOnInit(): void {
    this.states = this.locationService.getStates(this.countryCode);
    this.createForm();
    this.onChanges();
  }

  public isCanadianCode(code?: CountryCode) {
    code = code || this.contactInfoForm.controls.countryCode.value;

    return code === CountryCode.CA;
  }

  private setPostalMask(countryCode: CountryCode) {
    if (this.isCanadianCode(countryCode)) {
      this.contactInfoForm.controls.postalCode.setValidators([
        Validators.required,
        Validators.pattern(
          /^([ABCEGHJKLMNPRSTVXY])(\d)([ABCEGHJKLMNPRSTVWXYZ])( )(\d)([ABCEGHJKLMNPRSTVWXYZ])(\d)$/i
        )
      ]);

      const firstLetter = /[ABCEGHJKLMNPRSTVXY]/i;
      const letter = /[ABCEGHJKLMNPRSTVWXYZ]/i;
      const number = /\d/;

      this._postalCodeMask = [firstLetter, number, letter, ' ', number, letter, number];

      return;
    }

    this._postalCodeMask = [...Array(5).fill(/\d/), /-/, ...Array(4).fill(/\d/)];
    this.contactInfoForm.controls.postalCode.setValidators([
      Validators.required,
      Validators.pattern(/^\d{5}(?:-\d{4})?$/)
    ]);
  }
}
