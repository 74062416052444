import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { ModelFormGroup } from '@interface/form-type.model';
import { PaymentContact } from '@interface/payment';
import { PaymentMethodInfo } from '@interface/payment-method';

@Component({
  selector: 'app-new-payment-method',
  templateUrl: './new-payment-method.component.html'
})
export class NewPaymentMethodComponent implements OnInit {
  @Input() public inline = false;
  @Input() public bankForm: ModelFormGroup<PaymentMethodInfo>;
  @Input() public contactInfoForm: ModelFormGroup<PaymentContact>;
  @Input() public creditCardForm: ModelFormGroup<PaymentMethodInfo>;
  @Output() public done = new EventEmitter();
  @Output() public checkValidity = new EventEmitter();
  @Output() public formChanged = new EventEmitter<ModelFormGroup<PaymentMethodInfo>>();

  public tabIndex = 0;

  constructor(private fb: FormBuilder) {
    this.bankForm = this.fb.group({});
    this.contactInfoForm = this.fb.group({});
    this.creditCardForm = this.fb.group({});
  }

  public ngOnInit() {
    this.formChanged.emit(this.creditCardForm);
  }

  public validate(event: boolean) {
    this.checkValidity.emit(event);
  }

  public paymentMethodChanged(event: MatTabChangeEvent) {
    this.tabIndex = event.index;

    if (event.index === 0) {
      this.formChanged.emit(this.creditCardForm);
    } else {
      this.formChanged.emit(this.bankForm);
    }
  }
}
